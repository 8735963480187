@import "css/base.scss";

// Note: active = when being pressed

$btn-border-thin: pxToRem(1);
$btn-border-thick: pxToRem(4);
$btn-padding-x: pxToRem(24);
$btn-height: pxToRem(48);
$btn-min-width: pxToRem(180);

.button-icon {
    margin-right: 0.5rem;
}

a,button,input {
    &.btn {
        margin: 0 0 pxToRem(16);
        padding: 0 ($btn-padding-x - $btn-border-thin);
        border: pxToRem(1) solid $color-dark-65;
        border-radius: pxToRem(24);
        height: $btn-height;
        line-height: $btn-height - ($btn-border-thin * 2);
        min-width: $btn-min-width;
        background: transparent;
        color: $color-text;
        font-size: pxToRem(16);
        white-space: nowrap;

        &:not(:last-child) { margin-right: pxToRem(24); }

        &:hover { background: $color-light-10; color: $color-dark-80; }
        &:active { background: $color-unnamed-grey; text-decoration: none; }
        &:not(.disabled):active { text-decoration: none; }
        &:focus { border: $btn-border-thick solid $color-focus; padding: 0 ($btn-padding-x - $btn-border-thick); box-shadow: none; line-height: $btn-height - ($btn-border-thick * 2); }
        &:active:focus { border-width: $btn-border-thin; padding: 0 ($btn-padding-x - $btn-border-thin); line-height: $btn-height - ($btn-border-thin * 2); }
        &:not(:disabled):not(.disabled):active:focus { box-shadow: none; }
        &:disabled { border-color: $color-light-10; background-color: $color-light-10; color: $color-dark-80; opacity: 1; }

        @include media-breakpoint-down(xs) {
            margin-bottom: pxToRem(24);

            &:not(.btn-link) { display: block; width: 100%; }
        }
    }

    &.btn-primary {
        background: $color-primary; border-color: $color-primary; color: $color-text;

        &:hover { background: $color-primary-hover; border-color: $color-primary-hover; color: $color-dark-80; }
        &:not(:disabled):not(.disabled):active { background: $color-primary-active; border-color: $color-primary-active; color: $color-text; }
        &:focus { background: $color-primary; color: $color-text; }
        &:disabled { border-color: $color-light-10; background-color: $color-light-10; color: $color-dark-80; }
    }

    &.btn-light {
        border-color: $color-light; color: $color-light;

        &:not(:disabled):not(.disabled):hover { background: $color-dark-65; color: $color-light; }
        &:not(:disabled):not(.disabled):active { background: $color-dark-80; color: $color-light; }
        &:disabled { border-color: $color-light-20; background-color: $color-dark-80; color: $color-light-20; }
    }

    &.btn-subtle, &.btn-subtle-light{
        padding: 0; background: transparent; border-radius: 0; border-color: transparent; text-align: left;
        &.left { text-align: left; }
        &.right { text-align: right; }
        &.center { text-align: center; }

        &:not(:disabled):hover { background: transparent; text-decoration: underline; }
        &:active { background: transparent; padding: 0; color: $color-text; text-decoration: underline; }
        &:focus { padding-left: pxToRem(8); padding-right: pxToRem(8); }
        &:active:focus { border-color: transparent; padding: 0; }
        &:disabled { border-color: transparent; background-color: transparent; color: $color-dark-65; cursor: text; }

        @include media-breakpoint-down(xs) {
            &:not(.left):not(.right) { text-align: center; }
        }
    }

    &.btn-subtle {
        color: $color-focus;

        &:not(:disabled):hover { color: $color-focus; text-decoration: underline; }
        &:not(:disabled):active { color: $color-text; }
    }

    &.btn-subtle-light {
        color: $color-light; text-decoration: underline;

        &:not(:disabled):hover { color: $color-light-20; text-decoration: none; }
        &:disabled:hover { text-decoration: underline; }
        &:not(:disabled):active { color: $color-light; text-decoration: underline; }
    }

    &.btn-link {
        @include linkStyle;

        //overrides to default styling.
        background: none;
        border: none;
        padding: 0;
        min-width: 0;
        line-height: 1.5;
        height: inherit;
        margin: 0;
        vertical-align:  0%;

    &:not(:last-child) { margin-right: 0; }
        &:disabled {background-color: transparent; color: $color-dark-65; cursor: text; }
        &:hover { background: transparent; color: inherit }
        &:active { background: transparent; }
        &:focus { border: none; padding: 0; box-shadow: none; line-height: 0; }
        &:active:focus { border-width: 0; padding: 0; line-height: 0; }
    }
}
