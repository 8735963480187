@import 'css/base.scss';

a {
    &.arrow {
        @include text-large3-emphasis();
        color: $color-text;

        &:after {
            @include pseudo(inline-block, relative);
            margin-left: 0.75em;
            width: 1rem; height: 1rem;
            background: url('~images/ArrowDown.svg') no-repeat center center;
            @include rotate(270);
            transition: 0.3s;
        }

        &:hover, &:not(.disabled):active { 
            color: $color-text; text-decoration: none; 
            &:after { margin-left: 1.25em; }
        }
    }
}
