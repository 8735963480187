@import '~css/base.scss';

// Textbox + Textarea + Select: label + help text
.form-group, .body-container .form-group { 
    position: relative; 
    margin-bottom: calc(#{pxToRem(24)} - 0.1px);
    padding-bottom: 0.1px; // prevent collapsing margin from child elements for consistency
    
    > .label-wrapper { display: flex; justify-content: space-between; 
        > label { font-weight: bold; 
            &:empty { margin: 0; }
        }
        > .help { 
            margin-bottom: pxToRem(8); font-size: pxToRem(14); 
            &:empty { margin: 0; }
            > a { font-size: pxToRem(16) } 
        }

        &.help-wrap-xs { @include media-breakpoint-down(xs) { display: block; } }
    }
}

// Textbox + Textarea + Select: .inline-label
$inline-label-width: pxToRem(144);
$inline-label-margin: pxToRem(18);
$inline-label-offset: $inline-label-margin + $inline-label-width;

@mixin inlineLabel {
    > .label-wrapper { display: inline-block; vertical-align: top;
        > label { margin: 0 $inline-label-margin 0 0; width: $inline-label-width; line-height: $input-height; font-weight: normal; }
        > .help { display: none; }

        + .form-control, +.textbox, + .dropdown, + .flatpickr-input { 
            display: inline-block; width: calc(100% - #{$inline-label-offset}); 
        }

        + .selector-group {
           display: inline-flex; flex-wrap: wrap;
           margin: 0; 
           width: calc(100% - #{$inline-label-offset});
           
           > .form-check { margin-top: 0.7rem; margin-bottom: 0.3rem; }
        }

        ~ .error { padding-left: calc(#{$inline-label-offset} + #{pxToRem(22)}); background-position-x: $inline-label-offset; }
    }
}

.inline-label .form-group, .form-group.inline-label {
    @include media-breakpoint-up(sm) { @include inlineLabel() }
}

.inline-label-up-md .form-group, .form-group.inline-label-up-md {
    @include media-breakpoint-up(md) { @include inlineLabel() }
}
