@import 'css/constants/colors.scss';
@import 'css/constants/mixins.scss';


// The starting/minimum viewport width for each screen size
$screen-breakpoint-xs: 0;
$screen-breakpoint-sm: 600;
$screen-breakpoint-md: 864;
$screen-breakpoint-lg: 1140;
$screen-breakpoint-xl: 1440;


$header-height: pxToRem(64);
$header-padding: pxToRem(20);
$header-padding-left: pxToRem(20);
$header-logo-padding-top: pxToRem(10);
$header-logo-width: pxToRem(100);
$header-logo-height: pxToRem(38);

$menu-item-height: pxToRem(64);

$element-margin-bottom: pxToRem(16);

// Z-indexes
$header-z: 900;
$menu-z: $header-z + 1;
$right-menu-z: $menu-z + 1;
$back-to-top-z: 9;
$advanced-filter-z: 10;
$loading-overlay-z: 1000;


//#region ========== Form fields ==========

// Textbox + Textarea + Select
$input-color: $color-text;
$input-placeholder-color: $color-dark-65;
$input-disabled-bg: $color-light-10;
$input-padding-x: pxToRem(16);
$input-border-color: $color-dark-65;
$input-border-width: pxToRem(1);
$input-border-radius: 0;
$input-height: pxToRem(48) !default;
$input-transition-time: 0ms;


@mixin inputHighlight($color: $color-focus) {
    border-color: $color;
    outline: pxToRem(1) solid $color !important;  // !important is only to overwrite react-select style, otherwise you'll need to repeat this line in Dropdown.scss
    outline-offset: -2px;
    box-shadow: none;
}

@mixin textbox($vertical-padding: 0) {
    padding: $vertical-padding $input-padding-x;
    border: $input-border-width solid $input-border-color;
    height: $input-height;
    color: $input-color;
    transition: all $input-transition-time;

    @include placeholder { color: $input-placeholder-color; }

    &:not(:disabled):not(.has-error) {
        &:hover, &:focus { @include inputHighlight(); }
    }
    &:disabled { background-color: $input-disabled-bg; color: $input-placeholder-color; }
    &.has-error {
        &, &:hover, &:focus { @include inputHighlight($color-error); }
    }
}

.hcaptcha-container.has-error iframe { @include inputHighlight($color-error); }

// Checkbox + radio-button
$chk-size: pxToRem(24);

@mixin selector($border-width: $input-border-width) {
    $focus-border-width: $border-width * 2;
    $tick-size: pxToRem(13);

    /* Hide the actual checkbox/radio */
    position: absolute; left: -9999px;

    + label {
        position: relative; padding-left: ($chk-size + pxToRem(8)); cursor: pointer;

        /* The pretty checkbox / radio */
        &:before {
            @include pseudo();
            left: 0; top: 0;
            width: $chk-size; height: $chk-size; border: $border-width solid $input-border-color;
            background: $color-light;
            transition: all $input-transition-time;
        }

        /* The check-mark / dot */
        &:after {
            @include pseudo();
            top: 0;
            left: 0;
            width: $chk-size;
            height: $chk-size;
            transition: all $input-transition-time;
        }
    }

    /* Check-mark / dot visibility */
    &:not(:checked) + label:after { opacity: 0; transform: scale(0); }
    &:checked {
        + label:after { opacity: 1; transform: scale(1); }
    }

    /* Disabled style */
    &:disabled {
        cursor: default;
        + label {
            color: $color-dark-65; cursor: default;
            &:before { background-color: $input-disabled-bg; border-color: $input-disabled-bg; }
        }
    }

    /* Hover + Focus style */
    &:not(:disabled):not(.has-error) {
        + label:hover:before, &:focus + label:before {
            border: $focus-border-width solid $color-focus;
        }
    }

    /* Error style */
    &.has-error + label:before { border: $border-width solid $color-error; }
}

@mixin fieldErrorText() {
    margin: pxToRem(8) 0;
    background: url('~images/Error_InputField.svg') no-repeat left 0;
    background-size: pxToRem(16) pxToRem(24);
    padding-left: pxToRem(22);
    color: $color-utility-red;
}

//#endregion


//#region ========== Table ==========

$table-hpad: pxToRem(12);
$table-vpad: 0.5rem;

@mixin sortIcon() {
    content: '';
    display: inline-block;
    position: absolute; right: -1.25rem; bottom: pxToRem(3);
    width: 1rem; height: 1rem;
    background-image: url('~images/triangle-up.svg'); background-repeat: no-repeat; background-position: center; background-size: contain;
}

@mixin tableCommon() {
    color: $color-text;

    th, td {
        padding: $table-vpad $table-hpad; border: none; vertical-align: middle;

        // Column horizontal alignment
        &.center { text-align: center; }
        &.right { text-align: right; }
    }
}

@mixin tableDefault($oddRowColor: $color-light-10) {  // This has alternating row colour
    @include tableCommon();

    margin-bottom: pxToRem(24);

    th, td { font-weight: normal;

        // Checkbox column
        &.check, &.radio {
            > .form-check { margin: 0;
                > label { padding-left: 1.5rem; width: 0; }
            }
        }
    }

    thead {
        &.has-filter > tr.labels > th { padding-bottom: pxToRem(4); }

        > tr {
            &.labels > th { border: none; font-weight: bold;
                > span { position: relative;
                    &:not(:focus) > .sr-only { display: none }  // Sort information should only be read when reading the column header itself
                }

                &.sortable { cursor: default;
                    &:not(.sorted):hover > span:after { @include sortIcon(); opacity: 0.5; }
                }
                &.sorted {
                    > span:after { @include sortIcon(); }
                    &.desc > span:after { @include transform(rotate(180deg)); }
                }
            }

            // For fitting quick-filter fields into table header, just under the column labels
            &.filters > th {
                padding: 0 pxToRem(2) 1rem;

                .form-group { margin: 0;
                    > label { display: none; }
                }
            }

            &.loading > th { padding: 0 0 1rem; }
        }
    }

    tbody tr {
        &:nth-child(odd)  { background: $oddRowColor; }
        &:nth-child(even) { background: $color-light; }

        td { height: pxToRem(56); color: $color-text; }

        // The whole row is clickable, but <a> can only be put inside <td>, so we expand <a> to fill the <td>
        &.clickable {
            &:hover { background: $color-unnamed-light; box-shadow: 0 0 6px rgba(0,0,0,0.3); transform: scale(1,1); }

            > td:not(.check) { padding: 0;
                // For links in a table to go to a new page or do an action
                > a, > .btn-link {
                    display: flex; align-items: center;
                    padding: $table-vpad $table-hpad; width: 100%; height: 100%;
                    color: $color-text;

                    &:hover { text-decoration: none; }
                }

                // Column horizontal alignment
                &.center { > a, > .btn-link { justify-content: center;   } }
                &.right  { > a, > .btn-link { justify-content: flex-end; } }

                &.cell-link > a, > .btn-link {
                    color: $color-link;
                }
            }
        }
    }
}

@mixin tableGrid() {  // This is like the default style, but instead of alt row colors, each row has a thin border
    @include tableDefault($color-light);

    tbody tr {
        border: 1px solid $color-light-10;
    }
}

@mixin tableHighlightTotal() {  // This has white background and horizontal lines to separate rows, best wrapped in PaddedContainer
    @include tableCommon();
    border-collapse: separate; border-spacing: 0; // Firefox fix

    thead th { border: none; background: $color-light; }

    td, tfoot th { height: pxToRem(56); }

    tbody tr {
        td {
            border-top: 1px solid $color-light-10;
            background: $color-light;
        }

        &.highlight {
            > td { border: 0 solid $color-dark-80; border-width: 1px 0; font-weight: bold; }
            + tr > td { border-top: none; }
        }
    }

    tbody tr.footer > td, tfoot th {
        border: 0 solid $color-dark-80; border-width: 1px 0;
        background: $color-light-05;
        font-weight: bold;
    }
}

//#endregion


@mixin paddedContainer() {  // A padded container with white background, also used for wrapping table with the highlightTotal style
    margin-bottom: 3rem;
    padding: 1.5rem;
    background-color: white;
}

/* see comment in App.js
:export {
    breakpointXs: $screen-breakpoint-xs;
    breakpointSm: $screen-breakpoint-sm;
    breakpointMd: $screen-breakpoint-md;
    breakpointLg: $screen-breakpoint-lg;
    breakpointXl: $screen-breakpoint-xl;
}
*/