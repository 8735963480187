@import 'css/base.scss';

.title-dropdown {
    margin-bottom: 2rem;

    .rselect__control {
        box-shadow: 0 0 8px 0 $color-light-20;
        border: none;
        border-radius: 5px;
        padding: 1rem 0.75rem 1rem 1rem;

        .rselect__value-container {
            flex-wrap: nowrap;
            justify-content: space-between;
            padding-left: 0.5rem; padding-right: 0;
            background: no-repeat left center;
            div:not(.rselect__multi-value) {
                margin-left: 42px !important;
            }
           
            .rselect__single-value {
                flex: 1;
                position: static;
                min-width: 0;
                max-width: none;
                @include transform(none);

                @include text-large3-emphasis;
            }

            &:after {  // The arrow label
                @include media-breakpoint-down(xs) { display: none; }
            }
        }
    }

    &.has-icon .rselect__value-container { padding-left: 3.75rem; }
    &.icon-account .rselect__value-container { background-image: url('~images/pictogram-account.svg'); }
}
